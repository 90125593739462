import {NextPageContext} from 'next';
import {QueryClient} from 'react-query';
import {dehydrate} from 'react-query/hydration';
import {appRoutes, cookieNames} from '@/common/config';
import {createApiClient} from '../api/client';
import {ApiClient} from '../contexts/ApiContext';
import {UserRole} from '../api/types';
import {queries} from '../api/operations';

type SSRLoader = (
  queryClient: QueryClient,
  apiClient: ApiClient,
  ctx: NextPageContext,
) => Promise<void>;

export const ssrPrefetch = async (
  loaders: SSRLoader[],
  apiClient: ApiClient,
  queryClient: QueryClient,
  ctx: NextPageContext,
) => {
  if (loaders.length > 0) {
    const tasks = loaders.map(loader => loader(queryClient, apiClient, ctx));
    await Promise.all(tasks);
  }

  return dehydrate(queryClient);
};

export const getServerSidePropsPrefetch =
  (loaders: SSRLoader[], prefetchUser = false) =>
  async (ctx: NextPageContext) => {
    const apiClient = createSsrApiClient(ctx);
    const queryClient = new QueryClient();

    if (prefetchUser) {
      await getUser(apiClient, queryClient, ctx);
    }

    const dehydratedState = await ssrPrefetch(
      loaders,
      apiClient,
      queryClient,
      ctx,
    );

    return {
      props: {
        dehydratedState,
      },
    };
  };

async function getUser(
  apiClient: ApiClient,
  queryClient: QueryClient,
  ctx: NextPageContext,
) {
  const accessToken = ssrAccessToken(ctx);

  if (accessToken) {
    try {
      const user = await queryClient.fetchQuery(
        queries.auth.me,
        apiClient.users.me,
      );

      return user;
    } catch (error: any) {
      console.error({
        type: 'Unable to fetch user in SSR',
        message: error.message,
      });
      return null;
    }
  }

  return null;
}

export const getServerSidePropsAuthPrefetch =
  (role: UserRole, loaders: SSRLoader[]) => async (ctx: NextPageContext) => {
    const queryClient = new QueryClient();
    const apiClient = createSsrApiClient(ctx);
    const user = await getUser(apiClient, queryClient, ctx);
    const hasAccess = user?.roles.includes(role) ?? false;
    const isUserRequest = !ctx.req?.url?.includes('_next') ?? true;

    if (isUserRequest && !hasAccess) {
      return {
        redirect: {
          permanent: false,
          destination: appRoutes.auth.refreshFlow(ctx.req?.url),
        },
      };
    }

    const dehydratedState = await ssrPrefetch(
      loaders,
      apiClient,
      queryClient,
      ctx,
    );

    return {
      props: {
        dehydratedState,
      },
    };
  };

export function ssrAccessToken(ctx: NextPageContext) {
  // cookies are set by cookie-parser at /server/server.ts
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const cookieJar = ctx.req?.signedCookies ?? {};
  const accessToken = cookieJar[cookieNames.session];

  return accessToken;
}

export function createSsrApiClient(ctx: NextPageContext) {
  const getAccessToken = () => ssrAccessToken(ctx);
  const apiClient = createApiClient(getAccessToken);
  return apiClient;
}
